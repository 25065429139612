/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Button, YouTube } from '@swp/components'
import SiteHeader from '../../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Blue Hats"} description={"Blue Hats"}>
        <SiteHeader set="" currentLanguage={1} />

        <Column className="pb--60 pt--60" name={"0kzrgxo5oxc"} style={{"backgroundColor":"rgba(192, 192, 192, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Lions Eat First"}>
              </Title>

              <Text className="text-box" content={"Název skladby je zároveň heslem Karlose Vémoly, <br>jenž veřejně poukázal i na svou jemnou část osobnosti<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Button className="btn-box" href={"/cs/"} target={null} content={"Home"} use={"page"}>
              </Button>

              <YouTube embedId={"nd3q2k-H4Bo"} autoplay={false}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"6mtk2af7j5u"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Greetings From The Sky"}>
              </Title>

              <Text className="text-box" content={"Skladba Greetings From The Sky je inspirována cool jazzem<br>"}>
              </Text>

              <Button className="btn-box" url={"https://www.youtube.com/watch?v=MFbsfDxfzGM&list=PLkTj2Nk6txuh4gh56n0AJfzqqoOshkq4d&index=4"} href={"https://www.youtube.com/watch?v=MFbsfDxfzGM&list=PLkTj2Nk6txuh4gh56n0AJfzqqoOshkq4d&index=4"} target={"_blank"} content={"YouTube"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Ice Bath"}>
              </Title>

              <Text className="text-box" content={"Síla ledové koupele v hudbě<br>"}>
              </Text>

              <Button className="btn-box" url={"https://www.youtube.com/watch?v=Di_pi20HW6o&list=PLkTj2Nk6txuh4gh56n0AJfzqqoOshkq4d&index=2"} href={"https://www.youtube.com/watch?v=Di_pi20HW6o&list=PLkTj2Nk6txuh4gh56n0AJfzqqoOshkq4d&index=2"} target={"_blank"} content={"YouTube"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Coffee Therapist"}>
              </Title>

              <Text className="text-box" content={"Rychlý swing jako oslava všech baristek a baristů<br>"}>
              </Text>

              <Button className="btn-box" url={"https://www.youtube.com/watch?v=8qCorxXIGHQ&list=PLkTj2Nk6txuh4gh56n0AJfzqqoOshkq4d&index=3"} href={"https://www.youtube.com/watch?v=8qCorxXIGHQ&list=PLkTj2Nk6txuh4gh56n0AJfzqqoOshkq4d&index=3"} target={"_blank"} content={"YouTube"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"w7yb4347cw"} style={{"backgroundColor":"rgba(192, 192, 192, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Tiptoe Elephant"}>
              </Title>

              <Text className="text-box" content={"Tiptoe Elephant v podání Blue Hats<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube embedId={"rBAFguS_G1M"}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}