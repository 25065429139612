/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, Menu, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, ColumnWrapper, Title, Text, YouTube, Image, Subtitle } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader set="" currentLanguage={0} />

        <Column className="css-1c39s9k --border2 --full --parallax pb--30 pt--30" name={"uvod"} border={"2"} parallax={true} fullscreen={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/2002/7884d43fe14a41e7b9cedaf47aaf1f55_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/2002/7884d43fe14a41e7b9cedaf47aaf1f55_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/2002/7884d43fe14a41e7b9cedaf47aaf1f55_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/2002/7884d43fe14a41e7b9cedaf47aaf1f55_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/2002/7884d43fe14a41e7b9cedaf47aaf1f55_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/2002/7884d43fe14a41e7b9cedaf47aaf1f55_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/2002/7884d43fe14a41e7b9cedaf47aaf1f55_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/2002/7884d43fe14a41e7b9cedaf47aaf1f55_s=3000x_.jpg);
    }
  
    `}>
          
          <Column className="--menu mt--50 pb--12 pt--12" menu={true} name={"menu"} style={{"left":0,"top":-50,"position":"absolute"}}>
            
            <Menu >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox className="--style5 --center">
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--style3 fs--24 w--400 btn-box--invert" href={"/jazz"} style={{"style":5}} target={""} content={"&nbsp;<span style=\"\" wfd-id=\"5192\">Jazz &nbsp;</span>"}>
                </Button>

                <Button className="btn-box btn-box--style2 fs--24 w--400 btn-box--invert" href={"/pop-rock"} style={{"style":5,"backgroundColor":"rgba(114,78,51,1)"}} target={""} content={"<font color=\"#af7d23\">Pop-Rock</font>"}>
                </Button>

                <Button className="btn-box btn-box--style2 fs--24 w--400 btn-box--invert" href={"/film-like"} style={{"style":5,"backgroundColor":"rgba(120,80,41,1)"}} target={""} content={"<span style=\"color: rgb(185, 129, 34);\">Film- like</span>"}>
                </Button>

                <Button className="btn-box btn-box--style3 fs--24 w--400 btn-box--invert" href={"/classical"} style={{"style":5}} target={""} content={"Classical"}>
                </Button>

              </MenuWrapBox>

            </Menu>

          </Column>

          <ColumnWrap className="--center el--1 pb--30 flex--stretch" style={{"paddingBottom":null}} columns={"1"}>
            
            <ColumnWrapper style={{"marginTop":68,"paddingTop":103}}>
              
              <Title className="title-box title-box--left fs--72 title-box--invert" style={{"marginTop":54}} content={"Otto Orany"}>
              </Title>

              <Text className="text-box text-box--left fs--16 text-box--invert ls--12" style={{"marginTop":0,"marginBottom":64,"paddingBottom":20.140625}} content={"<br><br>Musical adventurer<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"y4wlqo4w9u"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Compassion"}>
              </Title>

              <Text className="text-box fs--18" content={"feat. Ivan Skřítek"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube embedId={"QC3zzknmoVY"} autoplay={false}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"kontakt"} style={{"paddingTop":null,"paddingBottom":null,"backgroundColor":"rgba(192, 192, 192, 1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --shape2 --center el--2 --full flex--stretch" style={{"border":"1em solid var(--color-main-3)","maxWidth":"","marginTop":0,"backgroundColor":"rgba(133,121,109,0.52)","marginBottom":19}} columns={"2"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"CONTACT"}>
              </Title>

              <Text className="text-box" content={"Exploration in the jazz, rock, pop, electronic, funk, classical music and avantguarde<br>"}>
              </Text>

              <Image alt={""} src={"https://cdn.swbpg.com/t/2002/e12a7ef3b4b24d379e8aeb1ea7b9cf54_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, 50vw" style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/2002/e12a7ef3b4b24d379e8aeb1ea7b9cf54_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2002/e12a7ef3b4b24d379e8aeb1ea7b9cf54_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2002/e12a7ef3b4b24d379e8aeb1ea7b9cf54_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2002/e12a7ef3b4b24d379e8aeb1ea7b9cf54_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"backgroundColor":"rgba(123,108,96,0.53)"}}>
              
              <Subtitle className="subtitle-box" content={"<br><br><br><br><br>"}>
              </Subtitle>

              <Subtitle className="subtitle-box mt--02" style={{"marginTop":null}} content={"info@otto-o.com"}>
              </Subtitle>

              <Subtitle className="subtitle-box mt--02" style={{"marginTop":null}} content={"<br>composer &amp; pianist<br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"vyhody"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--4" columns={"4"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"Jazz<br>"}>
              </Subtitle>

              <Text className="text-box" content={"<br>Jazz combined with styles of itself or different styles<br>"}>
              </Text>

              <Button className="btn-box" href={"/jazz"} content={"Jazz<br>"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":308}}>
              
              <Subtitle className="subtitle-box" content={"Pop-Rock<br>"}>
              </Subtitle>

              <Text className="text-box" content={"<br>Progressive pop containing rock, hard rock, funk and dance<br>"}>
              </Text>

              <Button className="btn-box" href={"/pop-rock"} content={"Pop-Rock<br>"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"Film-like<br>"}>
              </Subtitle>

              <Text className="text-box" content={"<br>Music influenced by film music furthermore by its telling stories<br>"}>
              </Text>

              <Button className="btn-box" href={"/film-like"} content={"Film-like<br>"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"Classical<br>"}>
              </Subtitle>

              <Text className="text-box" content={"<br>Classical music<br><br>"}>
              </Text>

              <Button className="btn-box" href={"/classical"} content={"Classical<br>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--10 pt--10" layout={"l4"} style={{"backgroundColor":"rgba(192, 192, 192, 1)"}} name={"0217oolhd77v"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/2002/3f7d196251f3435eba2d287c7eb1c75a_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/2002/3f7d196251f3435eba2d287c7eb1c75a_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2002/3f7d196251f3435eba2d287c7eb1c75a_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2002/3f7d196251f3435eba2d287c7eb1c75a_s=860x_.jpg 860w"}>
              </Image>

              <Text className="text-box" content={"<br>Studies, Cooperations, Achievements, Awards"}>
              </Text>

              <Button className="btn-box" content={"Bio"} use={"page"} href={"/bio"} target={null}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/2002/ac223b4b02cc423aaad7ec3eb9d8a834.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={""}>
              </Image>

              <Text className="text-box" content={"Creative Piano Textbook<br>My students<br>Tutorials<br>"}>
              </Text>

              <Button className="btn-box" content={"Education"} use={"page"} href={"/education"} target={null}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/2002/1d7395088c1d4a0faedf0facd5390c52_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/2002/1d7395088c1d4a0faedf0facd5390c52_s=350x_.png 350w, https://cdn.swbpg.com/t/2002/1d7395088c1d4a0faedf0facd5390c52_s=660x_.png 660w, https://cdn.swbpg.com/t/2002/1d7395088c1d4a0faedf0facd5390c52_s=860x_.png 860w"}>
              </Image>

              <Text className="text-box" content={"<br>Other kinds of music"}>
              </Text>

              <Button className="btn-box" content={"Additional Music"} target={null} use={"page"} href={"/additionalmusic"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1c39s9k --border2 pb--50 pt--50" name={"banner"} border={"2"} layout={"l1"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/2002/7884d43fe14a41e7b9cedaf47aaf1f55_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/2002/7884d43fe14a41e7b9cedaf47aaf1f55_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/2002/7884d43fe14a41e7b9cedaf47aaf1f55_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/2002/7884d43fe14a41e7b9cedaf47aaf1f55_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/2002/7884d43fe14a41e7b9cedaf47aaf1f55_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/2002/7884d43fe14a41e7b9cedaf47aaf1f55_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/2002/7884d43fe14a41e7b9cedaf47aaf1f55_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/2002/7884d43fe14a41e7b9cedaf47aaf1f55_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--72 title-box--invert" content={"OTTO ORANY<br>"}>
              </Title>

              <Text className="text-box fs--16 text-box--invert ls--12 mt--08" content={"<font color=\"#002625\">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; MUSICAL ADVENTURER</font><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}