/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Button, YouTube } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Creative Piano Textbook"}>
        <SiteHeader set="" currentLanguage={0} />

        <Column className="pb--10 pl--25 pr--25 pt--10" name={"[[T00name]]"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1 --full mt--10 pb--10" columns={"1"} fullscreen={true}>
            
            <ColumnWrapper className="pb--25" style={{"paddingBottom":null}}>
              
              <Title className="title-box mt--06" style={{"maxWidth":620,"marginTop":null}} content={"Website on progress"}>
              </Title>

              <Text className="text-box text-box--right mt--02" style={{"maxWidth":429,"marginTop":null}} content={""}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" style={{"backgroundColor":"rgba(192, 192, 192, 1)"}} name={"8twjv7hxn4r"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Joyful florist"}>
              </Title>

              <Text className="text-box" content={"Possible result of early studies<br>of improvisation and composition<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Button className="btn-box" content={"Home"} use={"page"} href={"/"}>
              </Button>

              <YouTube embedId={"ZeU4MzKEsi4"}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}