/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, Button, Title, Subtitle, Text } from '@swp/components'
import SiteHeader from '../../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Vzdělávání"}>
        <SiteHeader set="" currentLanguage={1} />

        <Column className="pb--10 pl--25 pr--25 pt--10" name={"[[T00name]]"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1 --full mt--10 pb--10" columns={"1"} fullscreen={true}>
            
            <ColumnWrapper className="--center pb--25" style={{"paddingBottom":null,"backgroundColor":"rgba(132,119,109,0.52)"}}>
              
              <Image className="swp-T00-mobile" alt={""} src={"https://cdn.swbpg.com/t/2002/f3f109cc9bfe483798492efb0a6fb9a5_s=660x_.jpg"} sizes="100vw" style={{"maxWidth":566}} srcSet={"https://cdn.swbpg.com/t/2002/f3f109cc9bfe483798492efb0a6fb9a5_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2002/f3f109cc9bfe483798492efb0a6fb9a5_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2002/f3f109cc9bfe483798492efb0a6fb9a5_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

              <Button className="btn-box" content={"Home"} use={"page"} href={"/cs/"}>
              </Button>

              <Title className="title-box mt--06" style={{"maxWidth":620,"marginTop":null}} content={"Vzdělávání"}>
              </Title>

              <Subtitle className="subtitle-box fs--20" content={"<span style=\"color: rgb(126, 73, 25);\">Roky vývoje a testování různých metodik<br>Nádherné výsledky mých studentů&nbsp;<br>(Tadeáš Syrový, Samuel Bloomfield, Richard Hučík atd.)<br></span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" style={{"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"0jf2y6c730k"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Tvořivá učebnice hry na klavír"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":399}} content={"První kreativní učebnice klavíru od úplných<br>začátků až po pokročilé<br>"}>
              </Text>

              <Button className="btn-box" content={"Učebnice"} use={"page"} href={"/creative-piano-textbook"} target={"_blank"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/2002/c4ff87dd4b1b4cdab297af3c7bb5c67f.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":400}} srcSet={""}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 flex--center" style={{"backgroundColor":"rgba(132,119,109,0.52)"}} columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/2002/1fb459a806e14519aa507d1e2afe86f0_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":400}} srcSet={"https://cdn.swbpg.com/t/2002/1fb459a806e14519aa507d1e2afe86f0_s=350x_.png 350w, https://cdn.swbpg.com/t/2002/1fb459a806e14519aa507d1e2afe86f0_s=660x_.png 660w, https://cdn.swbpg.com/t/2002/1fb459a806e14519aa507d1e2afe86f0_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Tutoriály"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":399}} content={"Pokročilejší tutoriály<br>zaměřené na improvizaci a skladbu<br>"}>
              </Text>

              <Button className="btn-box" content={"Tutoriály"} use={"page"} href={"/tutorials"} target={"_blank"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}