/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Button, YouTube } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Pop-Rock"} description={"Pop-rock"}>
        <SiteHeader set="" currentLanguage={0} />

        <Column className="[[ADMINpt]] [[ADMINpb]]" name={"9iw7l0zo4j5"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}}>
        </Column>


        <Column className="pb--60 pt--60" style={{"backgroundColor":"rgba(192, 192, 192, 1)"}} name={"tcm9cxfrim"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Compassion"}>
              </Title>

              <Text className="text-box" content={"feat. Ivan Skřítek<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Button className="btn-box" content={"Home"} use={"page"} href={"/"}>
              </Button>

              <YouTube embedId={"bMKGBJdCdt4"}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"302oke6jn2u"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Malá noční hudba"}>
              </Title>

              <Text className="text-box" content={"Little Hight Music on poem of contemporary czech poet Milan Mikulášek<br>feat. Vladimíra Krčková<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube embedId={"Dt44PEIihXw"}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"6vkf6jo22yq"} style={{"backgroundColor":"rgba(192, 192, 192, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Compassion"}>
              </Title>

              <Text className="text-box" content={"Videoclip<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube embedId={"INrXPGzj2qg"} autoplay={false}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"pbssjonnmek"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Bad"}>
              </Title>

              <Text className="text-box" content={"Michael Jackson sounds great in classic style (string quartet) and also jazz style (saxophone quartet)<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube embedId={"ifhJBHNxT6I"} autoplay={false}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"lq2fzbdibu"} style={{"backgroundColor":"rgba(192, 192, 192, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Fire"}>
              </Title>

              <Text className="text-box" content={"Hard Rock song<br>feat. Ivan Skřítek<br>"}>
              </Text>

              <Button className="btn-box" url={"https://www.youtube.com/watch?v=w_El03YhxyI&list=PLkTj2Nk6txugm4PBW9VeLpBbwS-RJe7B-&index=2"} href={"https://www.youtube.com/watch?v=w_El03YhxyI&list=PLkTj2Nk6txugm4PBW9VeLpBbwS-RJe7B-&index=2"} target={"_blank"} content={"YouTube"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Determination"}>
              </Title>

              <Text className="text-box" content={"Rock song with guitar"}>
              </Text>

              <Button className="btn-box" url={"https://www.youtube.com/watch?v=W_nneK5BQtQ&list=PLkTj2Nk6txugm4PBW9VeLpBbwS-RJe7B-&index=2"} href={"https://www.youtube.com/watch?v=W_nneK5BQtQ&list=PLkTj2Nk6txugm4PBW9VeLpBbwS-RJe7B-&index=2"} target={"_blank"} content={"YouTube"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Goodness"}>
              </Title>

              <Text className="text-box" content={"Lyrical song with violincello<br>"}>
              </Text>

              <Button className="btn-box" url={"https://www.youtube.com/watch?v=1xjR7bitcDY&list=PLkTj2Nk6txugm4PBW9VeLpBbwS-RJe7B-&index=4"} href={"https://www.youtube.com/watch?v=1xjR7bitcDY&list=PLkTj2Nk6txugm4PBW9VeLpBbwS-RJe7B-&index=4"} target={"_blank"} content={"YouTube"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"llyk3uwne7"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Calmness"}>
              </Title>

              <Text className="text-box" content={"Calm song with clarinet"}>
              </Text>

              <Button className="btn-box" url={"https://www.youtube.com/watch?v=uSul2MIgzqw&list=PLkTj2Nk6txugm4PBW9VeLpBbwS-RJe7B-&index=4&t=13s"} href={"https://www.youtube.com/watch?v=uSul2MIgzqw&list=PLkTj2Nk6txugm4PBW9VeLpBbwS-RJe7B-&index=4&t=13s"} target={"_blank"} content={"YouTube"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Warmness"}>
              </Title>

              <Text className="text-box" content={"Disco song"}>
              </Text>

              <Button className="btn-box" url={"https://www.youtube.com/watch?v=f1mUVjvHTx4&list=PLkTj2Nk6txugm4PBW9VeLpBbwS-RJe7B-&index=5"} href={"https://www.youtube.com/watch?v=f1mUVjvHTx4&list=PLkTj2Nk6txugm4PBW9VeLpBbwS-RJe7B-&index=5"} target={"_blank"} content={"YouTube"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Ode To Joy"}>
              </Title>

              <Text className="text-box" content={"Multigenre cover with flute, saxophone and violoncello"}>
              </Text>

              <Button className="btn-box" url={"https://www.youtube.com/watch?v=mBTdSqi9p48&list=PLkTj2Nk6txugm4PBW9VeLpBbwS-RJe7B-&index=10"} href={"https://www.youtube.com/watch?v=mBTdSqi9p48&list=PLkTj2Nk6txugm4PBW9VeLpBbwS-RJe7B-&index=10"} target={"_blank"} content={"YouTube"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}