/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Button, Title, Text, Image, YouTube } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Jazz"} description={"Jazz"}>
        <SiteHeader set="" currentLanguage={0} />

        <Column className="pb--30 pt--30" name={"x7iwilye80c"} style={{"backgroundColor":"rgba(192, 192, 192, 1)"}} layout={"l13"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Button className="btn-box pb--60" content={"Home"} use={"page"} href={"/"}>
              </Button>

              <Title className="title-box" content={"The Jazz Garden<br>"}>
              </Title>

              <Text className="text-box" content={"Album full of postmodern jazz<br>"}>
              </Text>

              <Button className="btn-box" href={"/thejazzgarden"} content={"The Jazz Garden<br>"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/2002/9755a2540a55486a838325ddb9a4e8c6_s=860x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":443}} srcSet={"https://cdn.swbpg.com/t/2002/9755a2540a55486a838325ddb9a4e8c6_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2002/9755a2540a55486a838325ddb9a4e8c6_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2002/9755a2540a55486a838325ddb9a4e8c6_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2002/9755a2540a55486a838325ddb9a4e8c6_s=1400x_.jpg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"btg3td40c1o"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"MB Jazz (Big Band)"}>
              </Title>

              <Text className="text-box" content={"Big band written for my friend and saxophonist, Marek Buble"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube embedId={"H5HUOsOJVzw"}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"90e4n47wdwf"} style={{"backgroundColor":"rgba(192, 192, 192, 1)"}} layout={"l13"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Blue Hats<br>"}>
              </Title>

              <Text className="text-box" content={"Songs made for jazz Q Blue Hats<br>"}>
              </Text>

              <Button className="btn-box" href={"/bluehats"} content={"Blue Hats<br>"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/2002/8c75e1466dbf4664a277da5c90ae6780_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":443}} srcSet={"https://cdn.swbpg.com/t/2002/8c75e1466dbf4664a277da5c90ae6780_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2002/8c75e1466dbf4664a277da5c90ae6780_s=660x_.jpg 660w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"90e4n47wdwf"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}} layout={"l13"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Neon Mandala<br>"}>
              </Title>

              <Text className="text-box" content={"Electronic Jazz album<br>"}>
              </Text>

              <Button className="btn-box" href={"/neonmandala"} content={"Neon Mandala<br>"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/2002/c3f11d7fe7694b169861572c27a639e2_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":443}} srcSet={"https://cdn.swbpg.com/t/2002/c3f11d7fe7694b169861572c27a639e2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2002/c3f11d7fe7694b169861572c27a639e2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2002/c3f11d7fe7694b169861572c27a639e2_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" style={{"backgroundColor":"rgba(192, 192, 192, 1)"}} name={"5ap9ru8aold"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Passion"}>
              </Title>

              <Text className="text-box" content={"polystylic composition rooted in the jazz<br>feat. Ivan Skřítek"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube embedId={"XQGbZB5TO3w"}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}