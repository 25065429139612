/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Button, YouTube } from '@swp/components'
import SiteHeader from '../../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Pop-Rock"} description={"Pop-rock"}>
        <SiteHeader set="" currentLanguage={1} />

        <Column className="[[ADMINpt]] [[ADMINpb]]" name={"9iw7l0zo4j5"} style={{"backgroundColor":"rgba(192, 192, 192, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Soucit"}>
              </Title>

              <Text className="text-box" content={"Rocková úprava<br><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Button className="btn-box" content={"Home"} use={"page"} href={"/cs/"}>
              </Button>

              <YouTube embedId={"bMKGBJdCdt4"}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"302oke6jn2u"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Malá noční hudba"}>
              </Title>

              <Text className="text-box" content={"Malá noční hudba na báseň současného autora Milana Mikuláška<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube embedId={"Dt44PEIihXw"}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"6vkf6jo22yq"} style={{"backgroundColor":"rgba(192, 192, 192, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Soucit"}>
              </Title>

              <Text className="text-box" content={"Videoklip<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube embedId={"INrXPGzj2qg"} autoplay={false}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"pbssjonnmek"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Bad"}>
              </Title>

              <Text className="text-box" content={"Michael Jackson zní dobře jak v jazzovém hávu, který zastupuje saxofonové kvarteto, <br>tak v klasickém, který zastupuje smyčcové kvarteto<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube embedId={"ifhJBHNxT6I"} autoplay={false}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"lq2fzbdibu"} style={{"backgroundColor":"rgba(192, 192, 192, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Oheň"}>
              </Title>

              <Text className="text-box" content={"Hard Rocková píseň<br>vokály Ivan Skřítek<br>"}>
              </Text>

              <Button className="btn-box" url={"https://www.youtube.com/watch?v=w_El03YhxyI&list=PLkTj2Nk6txugm4PBW9VeLpBbwS-RJe7B-&index=2"} href={"https://www.youtube.com/watch?v=w_El03YhxyI&list=PLkTj2Nk6txugm4PBW9VeLpBbwS-RJe7B-&index=2"} target={"_blank"} content={"YouTube"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Odhodlání"}>
              </Title>

              <Text className="text-box" content={"Rocková píseň s elektrickou kytarou<br>"}>
              </Text>

              <Button className="btn-box" url={"https://www.youtube.com/watch?v=W_nneK5BQtQ&list=PLkTj2Nk6txugm4PBW9VeLpBbwS-RJe7B-&index=2"} href={"https://www.youtube.com/watch?v=W_nneK5BQtQ&list=PLkTj2Nk6txugm4PBW9VeLpBbwS-RJe7B-&index=2"} target={"_blank"} content={"YouTube"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Dobrota"}>
              </Title>

              <Text className="text-box" content={"Lyrická píseň s violoncellem<br>"}>
              </Text>

              <Button className="btn-box" url={"https://www.youtube.com/watch?v=1xjR7bitcDY&list=PLkTj2Nk6txugm4PBW9VeLpBbwS-RJe7B-&index=4"} href={"https://www.youtube.com/watch?v=1xjR7bitcDY&list=PLkTj2Nk6txugm4PBW9VeLpBbwS-RJe7B-&index=4"} target={"_blank"} content={"YouTube"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"llyk3uwne7"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Klidnost"}>
              </Title>

              <Text className="text-box" content={"Klidná píseň s klarinetem<br>"}>
              </Text>

              <Button className="btn-box" url={"https://www.youtube.com/watch?v=uSul2MIgzqw&list=PLkTj2Nk6txugm4PBW9VeLpBbwS-RJe7B-&index=4&t=13s"} href={"https://www.youtube.com/watch?v=uSul2MIgzqw&list=PLkTj2Nk6txugm4PBW9VeLpBbwS-RJe7B-&index=4&t=13s"} target={"_blank"} content={"YouTube"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Vřelost"}>
              </Title>

              <Text className="text-box" content={"Disko píseň<br>"}>
              </Text>

              <Button className="btn-box" url={"https://www.youtube.com/watch?v=f1mUVjvHTx4&list=PLkTj2Nk6txugm4PBW9VeLpBbwS-RJe7B-&index=5"} href={"https://www.youtube.com/watch?v=f1mUVjvHTx4&list=PLkTj2Nk6txugm4PBW9VeLpBbwS-RJe7B-&index=5"} target={"_blank"} content={"YouTube"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Óda na radost"}>
              </Title>

              <Text className="text-box" content={"Multižánrový cover s flétnou, saxofonem a violoncellem<br>"}>
              </Text>

              <Button className="btn-box" url={"https://www.youtube.com/watch?v=mBTdSqi9p48&list=PLkTj2Nk6txugm4PBW9VeLpBbwS-RJe7B-&index=10"} href={"https://www.youtube.com/watch?v=mBTdSqi9p48&list=PLkTj2Nk6txugm4PBW9VeLpBbwS-RJe7B-&index=10"} target={"_blank"} content={"YouTube"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}