/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Button, YouTube } from '@swp/components'
import SiteHeader from '../../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Klasika"} description={"Film-like"}>
        <SiteHeader set="" currentLanguage={1} />

        <Column className="pb--60 pt--60" style={{"backgroundColor":"rgba(192, 192, 192, 1)"}} name={"jxmp4kt6nw"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Soothed Soul"}>
              </Title>

              <Text className="text-box" content={"Skladba o dvou větách&nbsp;<br>zkomponovaná pro Doležalovo kvarteto<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Button className="btn-box" content={"Home"} use={"page"} href={"/cs/"}>
              </Button>

              <YouTube embedId={"CKY1kyNOTuA"}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"kjjlt8xrt0t"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Planta Sapiens"}>
              </Title>

              <Text className="text-box" content={"Skladba zkomponovaná pro norský barokní<br>soubor Nivalis Barokk<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube embedId={"sGWNYeWpzG8"}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" style={{"backgroundColor":"rgba(192, 192, 192, 1)"}} name={"bly4cl2m4ed"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Starlight Sonata"}>
              </Title>

              <Text className="text-box" content={"Skladba o dvou větách napsaná pro<br>Jaroslava Škutu a Davida Šugárka<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube embedId={"C2TqpRfvPUE"}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"pelcgex9wh"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Musical Moment No. 1"}>
              </Title>

              <Text className="text-box" content={"Hudební drobnost pro klavír a různé styly"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube embedId={"wKcG_hZxlfE"}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"sil3ts81ctm"} style={{"backgroundColor":"rgba(192, 192, 192, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Coffee Therapy"}>
              </Title>

              <Text className="text-box" content={"Dvouvětá skladba zkomponovaná pro mého profesora klavíru, Davida Šugárka, a jeho stejně skvělého kolegy, cellisty Vojtěcha Urbana\n<br>1. Espresso Doppio\n<br>2. Cappuccino<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube embedId={"VEMOo4g_XZ0"}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"qvk0b6zmb1m"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Trumpetový koncert"}>
              </Title>

              <Text className="text-box" content={"Koncert pro trumpetu, cembalo, preparovaný klavír<br>a mockup orchestr ve třech větách<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube embedId={"PGdAiwsTe5g"}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" style={{"backgroundColor":"rgba(192, 192, 192, 1)"}} name={"qvk0b6zmb1m"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Pulsar"}>
              </Title>

              <Text className="text-box" content={"Mikrointervalová skladba<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube embedId={"SHp-xbjAr7c"}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}