/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Button, YouTube } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Tutorials"}>
        <SiteHeader set="" currentLanguage={0} />

        <Column className="pb--10 pl--25 pr--25 pt--10" name={"[[T00name]]"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1 --full mt--10 pb--10" columns={"1"} fullscreen={true}>
          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" style={{"backgroundColor":"rgba(192, 192, 192, 1)"}} name={"vjlofymjkxb"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Take The \"A\" Train"}>
              </Title>

              <Text className="text-box" content={"Zaměřeno na polyrytmy"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Button className="btn-box" content={"Home"} use={"page"} href={"/"} target={null}>
              </Button>

              <YouTube embedId={"m5O3_-Xn5-4"}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"3tate17gz9g"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Night And Day"}>
              </Title>

              <Text className="text-box" content={"Zaměřeno na použití základních stupnic<br>a akordů<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube embedId={"2-Ppg6_SOWA"}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}